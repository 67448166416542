import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";

const Return = () => {
    console.log('Return Loaded')

    const [status, setStatus] = useState(null);
    const [customerEmail, setCustomerEmail] = useState('');

    useEffect(() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const sessionId = urlParams.get('session_id');

        fetch(`https://www.iclabs.eu/backend/api/session-status?session_id=${sessionId}`)
            .then((res) => res.json())
            .then((data) => {
                setStatus(data.status);
                setCustomerEmail(data.customer_email);
            });
    }, []);
    console.log('All Good')

    if (status === 'open') {
        return (
            <Navigate to="/checkout" />
        )
    }

    if (status === 'complete') {
        return (
            <div className="bg-SC h-screen">
                <div>

                    <section id="success">
                        <p>
                            We appreciate your business! A confirmation email will be sent to {customerEmail}.

                            If you have any questions, please email <a href="mailto:orders@example.com">iclabs.sdev@gmail.com</a>.
                        </p>
                    </section>
                </div>
            </div>
        )
    }

    return null;
}

export default Return;