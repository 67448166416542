import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { themes } from '../helpers/nodered/themes'
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { APIchangeInstanceConfig } from "helpers/apiCalls";

type MyInstance = {
    assignedtouser: number;
    customname: string;
    ip: string;
    url: string;
    isassigned: boolean;
    port: number;
    state: string;
    region: string;
    paid: boolean;
    changedate: string;
    createddate: string;
    latency: number;
    credentialsusername: string;
    credentialspassword: string;
    persistentdirectory: string;
    healthstatus?: string;
    serviceid: number;
    hash: string;
    forwardedports: {
        port: string;
        externalPort: string;
    }[];
    hwUtilization: {
        cpu: number;
        ram: number;
        storage: number;
        network: number;
    };
    id: number;
    saas: string;
    name: string;
    vcpu: number;
    ram: number;
    storage: number;
    price: number;
    currency: string;
    active: boolean;
    numberofinstance: number;
};

type Instance = {
    id: number;
    saas: string;
    name: string;
    vcpu: number;
    ram: number;
    storage: number;
    price: number;
    currency: string;
    active: boolean;
    numberofinstance: number;
};

interface MyInstancesServerInfoSpecInfoProps {
    selectedServiceInfo: MyInstance;
}

export default function MyInstancesServerInfoSpecInfo({
    selectedServiceInfo,
}: MyInstancesServerInfoSpecInfoProps) {
    const [serverInfo, setServerInfo] = useState(selectedServiceInfo);
    const consoleCSS = "px-4 py-4 border-s border-PM shadow flex flex-col h-fit items-start relative";
    const themeList: string[] = useMemo<string[]>(() => themes, [])
    const themeSelectRef = useRef<any>()
    const [isThemeModal, setIsThemeModal] = useState<[boolean, string]>([false, ''])
    const [selectedTheme, setSelectedTheme] = useState('');

    useEffect(() => {
        setServerInfo(selectedServiceInfo);
    }, [selectedServiceInfo]);

    useEffect(() => console.log(isThemeModal), isThemeModal)


    const type = useMemo(() => {
        const saasType = serverInfo.saas;
        if (saasType === "Ubuntu") {
            return "Ubuntu";
        } else if (saasType === "PostgreSQL") {
            return "PostgreSQL";
        } else if (saasType === "Node-RED") {
            return "Node-RED";
        } else {
            return "Ubuntu";
        }
    }, [serverInfo]);

    const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) =>{
        e.stopPropagation()

        const formData = new FormData(e.currentTarget)
        const formObject = Object.fromEntries(formData)

        try{

            const response = await APIchangeInstanceConfig(serverInfo.hash,formObject)

        }catch(error){
            console.log(error)
        }




    }

    const PostgreSQLForm = () => (
        <div className=''>
            <form onSubmit={(e)=>handleSubmit(e)}className='px-4 py-10 grid grid-cols-1 grid-rows-4 w-full'>
                <div className={consoleCSS}>
                    <label htmlFor="form-instance-theme" className='text-lg font-semibold'>Backup:</label>
                    <select name="formInstanceSelectTheme" id="formInstanceSelectTheme" className="appearance-none w-full px-4 py-0.5 text-center h-full rounded-sm bg-SC focus:outline-none focus:ring-1 focus:ring-ACS ">
                        {/*<option value="" hidden>{'⏷   Themes'}</option>*/}
                        <option value="light" className='bg-ACS text-SC'>{'➤'} Every 5 min</option>
                        <option value="dark">Every hour</option>
                        <option value="custom">Every day</option>
                    </select>
                </div>
                <div className={consoleCSS}>
                    <label htmlFor="form-instance-theme" className='text-lg font-semibold'>http/https:</label>
                    <select name="formInstanceSelectTheme" id="formInstanceSelectTheme" className="appearance-none w-full px-4 py-0.5 text-center h-full rounded-sm bg-SC focus:outline-none focus:ring-1 focus:ring-ACS ">
                        {/*<option value="" hidden>{'⏷   Themes'}</option>*/}
                        <option value="light" className='bg-ACS text-SC'>{'➤'} https</option>
                        <option value="dark">http</option>
                    </select>
                </div>
                <div className={consoleCSS}>
                    <label htmlFor="form-instance-theme" className='text-lg font-semibold '>Password:</label>

                    <input type="password" className='w-full bg-PM px-1 rounded-sm' placeholder='min 12 characters' />

                </div>
                <div className={consoleCSS}>
                    <label htmlFor="form-instance-theme" className='text-lg font-semibold '>Backup provider:</label>
                    <div className="flex items-center gap-2">
                        <input type="checkbox" name="" id="" className='custom-checkbox appearance-none bg-SC  checked:bg-ACS rounded-sm checked:text-SC' checked />
                        <p>ICLabs</p>
                    </div>
                </div>
                <div className='w-full px-4 py-4  flex  h-fit items-start'>
                    <button type="submit" className='flex px-4 py-3 w-full justify-center rounded-md text-md font-medium bg-PM text-TC hover:bg-ACS hover:text-SC animate-primary'>
                        save changes
                    </button>
                </div>

            </form>
        </div>
    )

    const NodeREDForm = () => (
        <div className=''>

            <form onSubmit={(e)=>handleSubmit(e)} className='px-4 py-10 flex flex-col w-full'>

                <div className={consoleCSS}>
                    <label htmlFor="form-instance-theme" className='text-lg font-semibold'>Theme:</label>
                    <select onChange={(e) => {
                        setIsThemeModal([true, e.currentTarget.value]);
                        setSelectedTheme(e.currentTarget.value);
                    }}
                        value={selectedTheme}
                        ref={themeSelectRef}
                        name="formInstanceSelectTheme"
                        id="formInstanceSelectTheme"
                        className="appearance-none w-full  px-4 py-0.5 text-center h-full rounded-sm bg-SC focus:outline-none focus:ring-1 focus:ring-ACS ">
                        {<option value="" hidden>{'⏷   Themes'}</option>}
                        {themeList && themeList.map((t, key) => (
                            <option key={key} value={t}>
                                {t}
                            </option>
                        ))}
                    </select>

                </div>
                {isThemeModal[0] ? <div className={consoleCSS+' relative'}>
                    <img className="w-64 h-40" src={`/themesNR/${isThemeModal[1]}.png`} alt="theme" />
                    <button className=" w-6 h-6 absolute right-0 top-0  bg-SCS rounded-sm" onClick={()=>setIsThemeModal([false,''])}>X</button>
                </div> : null}
                <div className={consoleCSS}>
                    <label htmlFor="form-instance-theme" className='text-lg font-semibold'>http/https:</label>
                    <select name="formInstanceSelectTheme" id="formInstanceSelectTheme" className="appearance-none w-full px-4 py-0.5 text-center h-full rounded-sm bg-SC focus:outline-none focus:ring-1 focus:ring-ACS ">
                        {/*<option value="" hidden>{'⏷   Themes'}</option>*/}
                        <option value="light" className='bg-ACS text-SC'>{'➤'} https</option>
                        <option value="dark">http</option>

                    </select>
                </div>
                <div className={consoleCSS}>
                    <label htmlFor="form-instance-theme" className='text-lg font-semibold '>Password:</label>

                    <input type="password" className='w-full bg-PM px-1 rounded-sm' placeholder='min 12 characters' />

                </div>
                <div className={consoleCSS}>
                    <label htmlFor="form-instance-theme" className='text-lg font-semibold '>GitHub:</label>

                    <input type="text" className='w-full bg-PM rounded-sm px-1' placeholder='git::kokot' />

                </div>
                <div className='w-full px-4 py-4  flex  h-fit items-start'>
                    <button className='flex px-4 py-3 w-full justify-center rounded-md text-md font-medium bg-PM text-TC hover:bg-ACS hover:text-SC animate-primary'>
                        save changes
                    </button>
                </div>

            </form>
        </div>
    )

    const UbuntuForm = () => (
        <div className='w-full'>
            <form onSubmit={(e)=>handleSubmit(e)} className='px-4 py-10 grid grid-cols-1 grid-rows-4 w-full'>
                <div className={consoleCSS}>
                    <label htmlFor="form-instance-theme" className='text-lg font-semibold'>http/https:</label>
                    <select name="formInstanceSelectTheme" id="formInstanceSelectTheme" className="appearance-none w-full px-4 py-0.5 text-center h-full rounded-sm bg-SC focus:outline-none focus:ring-1 focus:ring-ACS ">
                        {/*<option value="" hidden>{'⏷   Themes'}</option>*/}
                        <option value="light" className='bg-ACS text-SC'>{'➤'} https</option>
                        <option value="dark">http</option>
                    </select>
                </div>
                <div className='w-full px-4 py-4  flex  h-fit items-start'>
                    <button className='flex px-4 py-3 w-full justify-center rounded-md text-md font-medium bg-PM text-TC hover:bg-ACS hover:text-SC animate-primary'>
                        save changes
                    </button>
                </div>

            </form>
        </div>
    )

    return (
        <div className="">
            {type === "PostgreSQL" && <PostgreSQLForm />}
            {type === "Node-RED" && <NodeREDForm />}
            {type === "Ubuntu" && <UbuntuForm />}
        </div>
    );
}
function useSelect(arg0: (state: any) => any) {
    throw new Error("Function not implemented.");
}

