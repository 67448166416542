import MongoDB from '../assets/services/mongodb.png';
import Grafana from '../assets/services/grafana.png';
import NodeRED from '../assets/services/nodered.png';
import PostgreSQL from '../assets/services/postgre.png';
import WooCommerce from '../assets/services/woocommerce.png';
import WordPress from '../assets/services/wordpress.png';
import Ubuntu from 'assets/services/ubuntu.png';
import { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
type MyInstance = {
    hash: any;
    thisServiceUniqueId: string;
    assignedtouser: number;
    status: string;
    customname: string;
    ip: string;
    url: string;
    isassigned: boolean;
    port: number;
    state: string;
    region: string;
    paid: boolean;
    changedate: string;
    createddate: string;
    latency: number;
    credentialsusername: string;
    credentialspassword: string;
    persistentdirectory: string;
    healthstatus: string;
    serviceid: number;
    forwardedports: {
        to: string;
        from: string;
    }[];
    hwUtilization?: {
        cpu?: number;
        ram?: number;
        storage?: number;
        network?: number;
    },
    id: number;
    saas: string;
    name: string;
    vcpu: number;
    ram: number;
    storage: number;
    price: number;
    currency: string;
    active: boolean;
    numberofinstance: number;
}
type Instance = {
    id: number;
    saas: string;
    name: string;
    vcpu: number;
    ram: number;
    storage: number;
    price: number;
    currency: string;
    active: boolean;
    numberofinstance: number;
}
interface MyInstancesServerListProps {

    myInstances: MyInstance[];
    showSelectedService: (service: any) => void;
    selectedId: string;

}


export default function MyInstancesServerList({ myInstances, showSelectedService, selectedId }: MyInstancesServerListProps) {
    const [serverArray, setServerArray] = useState(myInstances);
    console.log('selected', selectedId)

    useEffect(() => {
        setServerArray(myInstances)
    }, [myInstances])

    const toggleActive = (e: any) => {
        const id = e.currentTarget.parentNode.id;
        showSelectedService(id)
        console.log('toggleActive', id)
    }
    const formatServiceName = (name: string): string => {
        return name.replace(/[^a-zA-Z0-9]/g, '');
    };

    const imageMap: { [key: string]: string } = {
        MongoDB,
        Grafana,
        NodeRED,
        PostgreSQL,
        WooCommerce,
        WordPress,
        Ubuntu
    };
    const getStatusIcon = (status: string | boolean): string => {
        //🔴 🟠 🟡 🟢 🔵 🟣 ⚫ ⚪
        if (typeof status === 'string') {
            switch (status) {
                case 'deploying':
                    return '⚪';
                case 'stopped':
                    return '🔴';
                case 'running':
                    return '🟢';
                default:
                    return '⚫';

            }
        } else {
            switch (status) {
                case false:
                    return '🔴';
                case true:
                    return '🟢';
                default:
                    return '⚪';

            }
        }
    }

    const getHwUtilization = (utilization: number): string => {
        if (utilization === 0) {
            return '⚫'
        } else if (utilization > 0 && utilization < 35) {
            return '🟢';
        } else if (utilization >= 35 && utilization < 75) {
            return '🟡';
        } else if (utilization >= 75) {
            return '🔴';
        } else {
            return '⚫';
        }


    }
    return (<>
        {
            serverArray.map((service, key) => {
                return (
                    <div key={key}>
                        <div id={`${service.hash}`} className="w-full h-fit bg-SCS flex flex-col rounded-md  ">

                            <button onClick={(e) => toggleActive(e)} className={`flex flex-col items-center rounded-t-md justify-between gap-2 px-8 py-4 hover:bg-PM ${selectedId === service.hash ? 'bg-PM' : ''} ease-linear transition duration-70`}>
                                <div className="flex items-center gap-2 w-full justify-between">
                                    <div className='flex items-center gap-6'>
                                        <img src={(imageMap[formatServiceName(service.saas)])} alt="serverIcon" className='h-8' />
                                        <h4 className='code'>{service.customname}</h4>
                                    </div>
                                    <div className=''>
                                        <span className=' text-TR'>⟩</span>
                                    </div>
                                </div>
                            </button>

                            <div className="flex flex-col items-center justify-between gap-2 px-8">

                                <div className="flex gap-6 items-center px-8 py-4 border-2 border-SC ">
                                    <div className="flex flex-col items-end gap-1 py-2 px-4 bg-SC rounded-md w-32 ">
                                        <div className='flex items-center gap-2'>
                                            <p>{service.state !==null? service.state:'pending'}</p>
                                            <p>{getStatusIcon(service.state)}</p>
                                        </div>
                                        <div className='flex items-center gap-2'>
                                            <p>Billing</p>
                                            <p>{getStatusIcon(service.paid)}</p>
                                        </div>
                                    </div>
                                    <div className="flex items-center gap-2 py-2 px-4 bg-SC rounded-md ">
                                        <div className="flex flex-col gap-1 items-center">
                                            <p>{getHwUtilization(service?.hwUtilization?.cpu??0)}</p>
                                            <p>🔲</p>
                                        </div>
                                        <div className="flex flex-col gap-1 items-center">
                                            <p>{getHwUtilization(service?.hwUtilization?.ram??0)}</p>
                                            <p>🧮</p>
                                        </div>
                                        <div className="flex flex-col gap-1 items-center">
                                            <p>{getHwUtilization(service?.hwUtilization?.storage??0)}</p>
                                            <p>💽</p>
                                        </div>
                                        <div className="flex flex-col gap-1 items-center">
                                            <p>{getHwUtilization(service?.hwUtilization?.network??0)}</p>
                                            <p>🖧</p>
                                        </div>
                                    </div>
                                </div>



                            </div>
                            {/*<div className="flex flex-col w-full gap-2 px-8 pb-6">
                        <div className="flex w-full justify-between border-b border-SC">
                            <p>Health</p>
                            <p>90%</p>
                        </div>
                        <div className="flex w-full justify-between border-b border-SC">
                            <p>BackUp</p>
                            <p>Every day</p>
                        </div>
                        <div className="flex w-full justify-between border-b border-SC">
                            <p>App</p>
                            <p>Node-RED</p>
                        </div>
                    </div>*/}
                        </div>
                    </div>)
            }
            )
        }
        {/*RENT NEW___*/}
        <div>
            <Link to='/dashboard/rentnew'>

                <div className="w-full h-fit flex flex-col border-dashed border-2 border-SCS text-SCS hover:text-TR hover:border-TR rounded-md
        ease-linear transition duration-70 group">
                    <button className='flex flex-col items-center px-8 py-4 hover:bg-SCS   '>
                        <div className="flex items-center w-full">
                            <div className='flex items-center justify-center w-full'>
                                <h4>rent new</h4>
                            </div>
                        </div>
                    </button>

                </div>
            </Link>
        </div>
    </>)
}