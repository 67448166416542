import { APInotifyICL } from "../helpers/apiCalls"
export const notifyICL = (message: string, critical?: boolean) => {
    const typeCriticality = critical ? 'Critical' : 'Issue'
    const assebleMessage: string = `[${typeCriticality}][${formatDate(new Date())}] Error: ${message}`
    const actualLog: string[] = JSON.parse(localStorage.getItem('ICLerror') ?? '[]')
    const newLog = actualLog.concat(assebleMessage);

    APInotifyICL(newLog,critical).then((res: boolean) => {
        if (res === true) {
            localStorage.removeItem('ICLerror');
            return console.log('ICL has been notified:' + actualLog)
        } else {
            localStorage.setItem('ICLerror', JSON.stringify(newLog));
        }
    });
    return 
}
const formatDate = (date: Date): string => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}