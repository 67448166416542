import { useEffect, useMemo, useState } from 'react';
import Button from 'UI/Button';
import { useDispatch, useSelector } from 'react-redux';
//import { StripeModal } from './modals/StripeModal'
import StripeModal from "../../checkout/StripeModal"
import { finalOrderData } from 'helpers/allServicesReducer';
import { toastNotification } from '../../reusables/toastNotification';
import { notifyICL } from 'helpers/notifyICL';

interface Additional {
    name: string;
    price: number;
    active: boolean;
}


export default function RentNew() {
    const allServices = useSelector((state: any) => state.services.services);
    const emailSelector = useSelector((state: any) => state.services?.user?.emails?.[0]?.value ?? 'none')
    const userEmail = useMemo(() => {
        return emailSelector;
    }, [emailSelector]);
    const categories = useMemo(() => {
        return new Set<string>(allServices.map((service: any) => service.saas));
    }, [allServices]);
    const arrCategories: string[] = Array.from(categories);
    const listMaxItems = useMemo(() => 3, []);
    const dispatch = useDispatch<any>()

    const listLength = arrCategories.length;
    const firstItem = arrCategories[0]
    const [selectedServiceName, setSelectedServiceName] = useState<string>('Ubuntu');
    const lastItem = arrCategories.indexOf(arrCategories[listLength - 1])

    const [cursor, setCursor] = useState<number>(0);
    const cursorMax = listLength - listMaxItems;
    const visibleList = arrCategories.slice(cursor, cursor + listMaxItems);
    const sortedServices = useMemo(() => {
        const chosenAppArr = allServices.filter((service: any) => service.saas === selectedServiceName);
        console.log(chosenAppArr)
        return chosenAppArr.sort((a: any, b: any) => a.price - b.price);
    }, [selectedServiceName]);

    const [selectedServiceSingle, setSelectedServiceSingle] = useState<any>(null);
    const chooseTierText = useMemo(() => {
        return { name: 'Please Choose Your Tier Of Instance !' }
    }, [selectedServiceName])
    const [payModalOpen, setPayModalOpen] = useState(false)
    const [clientSecret, setClientSecret] = useState<string | null>(null)
    const [productId, setProductId] = useState('0')

    interface TotalPrice {
        price: number;
        baseElement: boolean;
    }

    const [totalPrice, setTotalPrice] = useState<TotalPrice[]>([
        {
            price: 0,
            baseElement: true
        },
    ]);
    const [allPricesSum, setAllPricesSum] = useState<number>(0);
    const [additionals, setAdditionals] = useState<Additional[]>([
        { name: 'ICL Backup', price: 2.99, active: false },
        { name: 'Auto Redeployer', price: 3.99, active: false },
    ]);
    const [loading, setLoading] = useState<boolean>(false);
    const [fullDataToBackEnd, setFullDataToBackEnd] = useState<any>(null)

    useEffect(() => {
        setSelectedServiceSingle(null)
    }, [selectedServiceName])

    useEffect(() => {
        setTotalPrice(prev => {
            const baseElement = prev.find((el: any) => el.baseElement === true);
            if (baseElement) {
                baseElement.price = Number(selectedServiceSingle?.price ?? 0);
            }
            return [...prev];
        });
        console.log(totalPrice)
    }, [selectedServiceSingle])
    useEffect(() => {
        const basePriceSum = totalPrice.reduce((acc, curr) => acc + curr.price, 0);
        const additionalsSum = additionals
            .filter(additional => additional.active)
            .reduce((acc, curr) => acc + curr.price, 0);

        setAllPricesSum(basePriceSum + additionalsSum);
    }, [totalPrice, additionals]);



    const infoText: { [key: string]: { h3: string, p: string } } = useMemo(() => ({
        null: {
            h3: 'Add to the fleet !',
            p: 'Plese choose from options. Connect your devices, APIs and online services in new and interesting ways.'
        },
        'Node-RED': {
            h3: 'NodeRED',
            p: 'Node-RED is a multipurpose low-code programming tool for wiring together hardware devices, APIs and online services in new and interesting ways.'
        },
        PostgreSQL: {
            h3: 'PostgreSQL',
            p: 'PostgreSQL is a powerful, open source object-relational database system with over 30 years of active development that has earned it a strong reputation for reliability.'
        },
        Ubuntu: {
            h3: 'Ubuntu',
            p: 'Ubuntu is mostly of free and open-source software. Ubuntu is officially released in three editions: Desktop, Server, and Core for Internet of things devices and robots.'
        },
        Grafana: {
            h3: 'Grafana',
            p: 'Grafana is  analytics and interactive visualization web application. It provides charts, graphs, and alerts for the web when connected to supported data sources.'
        }
    }), []);


    const imageMap: { [key: string]: string } = {
        'Node-RED': '/services/nodered.png',
        PostgreSQL: '/services/postgre.png',
        Ubuntu: '/services/ubuntu.png',
        Grafana: '/services/grafana.png',
        MongoDB: '/services/mongodb.png',
        WooCommerce: '/services/woocommerce.png',
        WordPress: '/services/wordpress.png',
    };

    const isSelectedService = (service: any) => {
        const selectStyles = 'bg-ACS -translate-y-4'
        return selectedServiceSingle === service ? selectStyles : 'bg-TR'
    }
    const isSelectedCategory = (category: any) => {
        const selectStyles = 'bg-PM'
        return selectedServiceName === category ? selectStyles : ''
    }
    const adjustPriceByAdditional = (name: string) => {
        setAdditionals(prev =>
            prev.map(additional =>
                additional.name === name
                    ? { ...additional, active: !additional.active }
                    : additional
            )
        );
    };


    const isSelectedAdditional = (name: string) => {
        const selectedAditionals = additionals.some(additional => additional.active === true);
        if (selectedServiceSingle === null && selectedAditionals) {
            setAdditionals(prev => prev.map(additional => ({ ...additional, active: false })))
            return 'border-transparent'
        }
        return additionals.find(additional => additional.name === name)?.active ? 'border-ACS' : 'border-transparent';
    }

    const submitNewInstance = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (selectedServiceSingle === null || selectedServiceSingle === undefined || allPricesSum === 0) return
        console.log('userEmail: ', userEmail)
        if (userEmail === 'none' || null || undefined) return

        const formData = new FormData(e.currentTarget);
        const formObject = Object.fromEntries(formData);
        setFullDataToBackEnd({ ...formObject, selectedServiceSingle, additionals, allPricesSum })

        console.log(fullDataToBackEnd);
        setLoading(true)

        try {
            dispatch(finalOrderData(fullDataToBackEnd))
            toastNotification('info','Order created, please proceed..')
        }
        catch(error:any) {
            toastNotification('error','something went wrong')
            notifyICL('Unknown FrontEnd error '+'[RentNew.ts,submitNewInstance AFN, catch]: ' + error, true);
         }

        // setLoading(false)
        //dispatch(fetchMyInstances())
        /* toast.success('🎉 Your instance is running 🎉', {
             position: "top-right",
             autoClose: 5000,
             hideProgressBar: false,
             closeOnClick: true,
             pauseOnHover: true,
             draggable: true,
             progress: undefined,
         });*/

    }

useEffect(() => {
    if (fullDataToBackEnd && fullDataToBackEnd.selectedServiceSingle) {
        setPayModalOpen(true);
    }
}, [fullDataToBackEnd])

const handleCloseModal = () => {
    setPayModalOpen(false);
    setClientSecret(null);
    setLoading(false)

};
const toggleStripeModal = () => {
    setPayModalOpen(false)
}

return (
    <div className="w-full h-fit bg-SC border  border-SCS flex flex-col items-center pb-16">

        <div className="text-SCS py-8 text-center">
            <h3>Rent new instance</h3>
            <p>Choose from options</p>

        </div>

        <div className='w-full '>
            <div className='flex flex-col gap-4 items-center'>

                <div className="flex flex-col items-center h-fit  bg-SCS rounded-md  w-2/3 ">

                    <div className='w-full flex items-center bg-SC border-SCS border h-fit rounded-t-md px-4 '>
                        <h3 className='code'> Choose instance</h3>

                    </div>
                    <div className='flex w-full border-x border-b border-SCS'>
                        <div className='flex items-center gap-4 px-16 py-2 overflow-scroll w-2/3 relative' >
                            {visibleList.map((category: string, key: number) => (

                                <button key={key} onClick={() => setSelectedServiceName(category)} className={`flex flex-col items-center gap-2 hover:bg-PM ${isSelectedCategory(category)} px-4 py-2 rounded-md`}>
                                    <img src={(imageMap[category])} alt={category} className='h-16' />
                                    <p>{category}</p>
                                </button>

                            ))}
                            <button onClick={() => setCursor(prev => prev + 1)} className={`absolute right-0 px-4 mr-2 py-2 hover:bg-SC ${cursor + listMaxItems - 1 === lastItem ? 'hidden' : 'block'}`}>⮞</button>
                            <button onClick={() => setCursor(prev => prev - 1)} className={`absolute left-0 px-4 ml-2 py-2 hover:bg-SC ${cursor === 0 ? 'hidden' : 'block'}`}>⮜</button>

                        </div>
                        <div className='bg-SC px-4 py-2 w-full flex flex-col gap-2'>
                            <h3 className=''>{infoText[selectedServiceName].h3}</h3>
                            <p>
                                {infoText[selectedServiceName].p}
                            </p>
                            <div className='flex items-center gap-4 self-end py-2'>
                                <Button leadTo={`/education/${selectedServiceName.toLocaleLowerCase()}`} type='tertiary' >
                                    Learn more
                                </Button>
                            </div>

                        </div>
                    </div>
                </div>




                <div className='bg-SC w-full h-fit flex items-center  overflow-scroll py-20'>
                    <div className='grid grid-cols-4 grid-rows-1 w-full justify-items-center items-center px-32  '>
                        {
                            sortedServices.filter((service: any) => service.saas === selectedServiceName).map((service: any, key: number) => (
                                <button
                                    onClick={() => setSelectedServiceSingle(service)}
                                    className={`w-64 h-fit text-SC pt-10 rounded-3xl  border-transparent hover:bg-ACS ${isSelectedService(service)}`}>
                                    <div className='absolute inline-flex items-center justify-center gap-1 py-5 bg-SCS text-ACS -translate-x-1/2 -translate-y-16 rounded-3xl w-[290px] rounded-t-2xl'>
                                        <h3 className='code text-3xl'>{service.price}$</h3>
                                        <p className='text-TR'> / month</p>
                                    </div>
                                    <div className='flex flex-col items-center gap-1 py-6 mt-6'>
                                        <img src={(imageMap[selectedServiceName])} className='h-16' alt="" />
                                        <h3 className='font-bold'>{service.name.split(" ")[1]}</h3>
                                    </div>
                                    <div>
                                        <div className='flex flex-col py-4 pb-10 w-full px-8 gap-2 bg-SCS text-TR font-bold'>
                                            <div className='flex items-center gap-2 justify-between '>
                                                <p>🔲 CPU:</p>
                                                <p>{service.vcpu} vCPU</p>
                                            </div>
                                            <div className='flex items-center gap-2 justify-between'>
                                                <p>🧮 RAM:</p>
                                                <p>{service.ram} GB</p>
                                            </div>
                                            <div className='flex items-center gap-2 justify-between'>
                                                <p>💽 Storage:</p>
                                                <p>{service.storage} GB</p>
                                            </div>
                                        </div>
                                    </div>
                                </button>
                            ))}
                    </div>

                </div>

                <form className='ease-linear transition duration-300 bg-SCS rounded-md h-fit mb-16 flex flex-col  w-2/3' onSubmit={(e) => submitNewInstance(e)}>
                    <div className='w-full flex items-center bg-SC border-SCS border h-fit rounded-md px-4'>
                        <h3 className='code'> Your desired instance</h3>

                    </div>
                    <div className='flex items-center gap-12 justify-between px-6 py-2 border-b border-SC'>
                        <div>
                            <h4>{selectedServiceSingle?.name ?? chooseTierText.name}</h4>
                        </div>


                    </div>



                    <div className='flex py-4 px-4  justify-start gap-8 items-center border-b border-SC'>
                        <div className='bg-SCS w-fit px-4 py-2 rounded-md h-fit'>
                            <img src={(imageMap[selectedServiceName])} className='w-16 ' alt="" />

                        </div>
                        <div className='flex w-full  justify-between px-2'>

                            <div className='flex flex-col py-2 w-1/3 gap-2'>
                                <div className='flex items-center gap-2 justify-between'>
                                    <p>🔲 CPU:</p>
                                    <p>{selectedServiceSingle?.vcpu ?? '0'} VCU</p>
                                </div>
                                <div className='flex items-center gap-2 justify-between'>
                                    <p>🧮 RAM:</p>
                                    <p>{selectedServiceSingle?.ram ?? '0'} GB</p>
                                </div>
                                <div className='flex items-center gap-2 justify-between'>
                                    <p>💽 Storage:</p>
                                    <p>{selectedServiceSingle?.storage ?? '0'} GB</p>
                                </div>
                            </div>
                            <div className='flex flex-col py-2 w-1/3 gap-2'>
                                <div className='flex items-center w-full justify-between'>
                                    Locality:
                                    <select name="localitySelect" id="rentSelectLocal" className="appearance-none w-24 pr-4 pl-3 h-full rounded-sm bg-SC focus:outline-none focus:ring-1 focus:ring-ACS ">
                                        <option value="auto" selected>auto</option>
                                        <option value="usa">USA</option>
                                        <option value="Europe">Europe</option>
                                        <option value="Asia">Asia</option>

                                    </select>
                                </div>
                                <div className='flex items-center w-full justify-between'>
                                    Http/Https:
                                    <select name="httpSelect" id="rentSelectHttp" className="appearance-none w-24 pr-4 pl-3 h-full rounded-sm bg-SC focus:outline-none focus:ring-1 focus:ring-ACS ">
                                        <option value="http">http</option>
                                        <option value="https" selected>https</option>
                                    </select>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className='grid grid-cols-3 py-8 px-6  w-full  gap-8 border-b border-SC'>
                        <div className='bg-SC col-span-2 rounded-md flex flex-col w-full items-center'>
                            <div className='flex flex-col h-2/3 items-center gap-4 justify-center'>
                                <div className='flex '>
                                    <h3 className='code text-ACS inline-flex text-4xl items-center gap-2'>
                                        {allPricesSum.toFixed(2)}$
                                        <p className='text-TR text-sm'> / month</p>

                                    </h3>
                                </div>
                                <div>
                                    <p className='text-TR'>{allPricesSum * 12}$ yearly</p>
                                </div>
                            </div>
                            <div className='gap-2 flex flex-col text-center'>
                                <p>Would you like to also integrate:</p>
                                <div className='flex items-center gap-2'>
                                    {
                                        additionals.map((additional: any, key: number) => (
                                            <button type='button' onClick={() => adjustPriceByAdditional(additional.name)} className={`bg-SCS px-4 py-2 rounded-md border ${isSelectedAdditional(additional.name)}`}>
                                                <p>{additional.name}</p>
                                                <p>{additional.price.toFixed(2)}$ / month</p>
                                            </button>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col col-span-1 gap-4'>
                            <label htmlFor="" className=''> Name
                                <input name='nameOfInstance' type="text" className='w-full bg-PM rounded-sm px-1' placeholder='my sick app' required />
                            </label>
                            <label htmlFor=""> Password

                                <input name='instancePassword' type="password" className='w-full bg-PM rounded-sm px-1' placeholder='the longer the better' required />
                            </label>
                            <label htmlFor=""> GitHub

                                <input name='github' type="text" className='w-full bg-PM rounded-sm px-1' placeholder='TBD' />
                            </label>
                            <label htmlFor="form-instance-theme" className='text-lg font-semibold '>Backup provider:</label>
                            <div className="flex items-center gap-2">
                                <input name='iclabsProvider' type="checkbox" id="formProvider" className='custom-checkbox appearance-none bg-SC  checked:bg-ACS rounded-sm checked:text-SC' checked required />
                                <p>ICLabs</p>
                            </div>
                            <div className="flex items-center gap-2 pt-4">
                                <input type="checkbox" name="Terms" id="formTermsAndConds" className='custom-checkbox appearance-none bg-SC  checked:bg-ACS rounded-sm checked:text-SC' required />
                                <p>Terms & Conditions</p>
                            </div>
                            <div className='w-full px-4 py-4  flex  h-fit items-start'>
                                <button type='submit' className='flex px-4 py-3 w-full justify-center rounded-md text-md font-medium bg-PM text-TC hover:bg-ACS hover:text-SC animate-primary items-center' disabled={loading}>
                                    {loading === true ? (<span className="loader mr-4"></span>) : ''}  {loading === true ? 'Submitting Order' : 'Pay and deploy'}
                                </button>
                            </div>
                        </div>



                    </div>
                </form>
            </div>
        </div>

        {payModalOpen &&
            <div onClick={() => toggleStripeModal()} className={`${payModalOpen === true ? 'flex' : 'hidden'} loginModal items-center justify-center fixed left-0 top-0 w-full h-screen  `}>

                <div onClick={(e) => e.stopPropagation()} className='w-1/4 h-fit border border-ACS z-50 relative shadow-md shadow-ACS'>
                    <StripeModal
                        fullDataToBackEnd={fullDataToBackEnd}
                    />
                </div>
            </div>}
    </div>
)
}