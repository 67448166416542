import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { arrayBuffer } from "stream/consumers";

export const fetchServices = createAsyncThunk(
    "services/fetchServices",
    async () => {
        const response = await fetch("https://iclabs.eu/backend/api/getallservices",);
        return response.json()
    }
)
export const fetchUser = createAsyncThunk(
    "services/fetchUser",
    async () => {
        const response = await fetch("https://iclabs.eu/backend/api/getuser", {
            credentials: 'include'
        })
        return response.json()
    }
)

export const fetchMyInstances = createAsyncThunk(
    "services/fetchMyInstances",
    async () => {
        const response = await fetch("https://iclabs.eu/backend/api/getmyinstances", {
            credentials: 'include'
        })
        return response.json()
    }
)

export const getAllNRThemePics = createAsyncThunk(
    "services/getAllNRThemePics",
    async () => {
        const baseUrl = 'https://iclabs.eu/backend/api/assets'
        const queryParams = new URLSearchParams({ command: 'all', path: 'noderedThemes' })
        const fullPath = `${baseUrl}?${queryParams}`;

        const resArray = await fetch(fullPath)
        const result = await resArray.json()
        return result;
    }
);

export const allServicesSlice = createSlice({
    name: 'services',
    initialState: {
        services: [],
        loading: false,
        error: '',
        selectedServiceLanding: [
            {
                id: 1,
                saas: "Node-RED",
                name: "Node-RED ESSENTIAL",
                vcpu: 1,
                ram: 1,
                storage: 5,
                price: "5.00",
                currency: "EUR",
                active: true,
                numberofinstances: 1
            },
            {
                id: 3,
                saas: "Node-RED",
                name: "Node-RED ADVANCED",
                vcpu: 2,
                ram: 2,
                storage: 30,
                price: "9.00",
                currency: "EUR",
                active: true,
                numberofinstances: 1
            },
            {
                id: 2,
                saas: "Node-RED",
                name: "Node-RED BASIC",
                vcpu: 1,
                ram: 2,
                storage: 10,
                price: "7.00",
                currency: "EUR",
                active: true,
                numberofinstances: 1
            },
            {
                id: 4,
                saas: "Node-RED",
                name: "Node-RED METAL",
                vcpu: 4,
                ram: 8,
                storage: 128,
                price: "19.00",
                currency: "EUR",
                active: true,
                numberofinstances: 1
            }
        ],
        user: {
            id: 'none',
            displayName: 'none',
            email: 'none',
        },
        userError: '',
        userLoading: false,
        myInstances: [],
        myInstancesError: '',
        myInstancesLoading: false,
        myInstancesThemePic: ['a'],
        finalOrderData: {}
    },
    reducers: {
        changeSelectedServiceLanding: (state, action) => {
            state.selectedServiceLanding = action.payload;
        },
        finalOrderData: (state, action) => {
            state.finalOrderData = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder.addCase(fetchServices.pending, (state) => {
            state.loading = true;
            state.error = '';
        });
        builder.addCase(fetchServices.fulfilled, (state, action) => {
            state.services = action.payload;
            state.loading = false;
        });
        builder.addCase(fetchServices.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error as string;
        });

        builder.addCase(fetchUser.pending, (state) => {
            state.userLoading = true;
            state.userError = '';
        });
        builder.addCase(fetchUser.fulfilled, (state, action) => {
            state.userLoading = false;
            state.user = action.payload;
        })
        builder.addCase(fetchUser.rejected, (state, action) => {
            state.userLoading = false;
            state.userError = 'Failed to fetch user';
        })

        builder.addCase(fetchMyInstances.pending, (state) => {
            state.myInstancesLoading = true;
        })
        builder.addCase(fetchMyInstances.fulfilled, (state, action) => {
            state.myInstancesLoading = false;
            state.myInstances = action.payload;


        })
        builder.addCase(fetchMyInstances.rejected, (state) => {
            state.myInstancesLoading = false;
            state.myInstancesError = 'Failed to fetch my instances';



        })
        /*builder.addCase(getAllNRThemePics.fulfilled, (state, action) => {//TODO DOKONCIT LOGIC
            state.myInstancesThemePic = action.payload as any;


        })*/
        builder.addCase(getAllNRThemePics.fulfilled, (state, action) => {
                state.myInstancesThemePic = action.payload as any;
            })
    },

});

export default allServicesSlice.reducer;
export const { finalOrderData } = allServicesSlice.actions;
