import DashSidePanel from "../components/DashSidePanel";
import DashMain from "../components/DashMain";
import { Outlet } from "react-router-dom";

export default function Dashboard() {

    return (
        <div className="flex bg-SC">
            <DashSidePanel />
            <Outlet />
        </div>
    );
}