import React, { useCallback, useState, useEffect } from "react";
import {loadStripe} from '@stripe/stripe-js';
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout
} from '@stripe/react-stripe-js';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);

const CheckoutForm = (fullDataToBackEnd: any) => {
  console.log('CheckoutForm Loaded')
  const fetchClientSecret = useCallback(() => {
    // Create a Checkout Session
    return fetch("https://iclabs.eu/backend/api/neworder", {
      method: "POST",
      body: JSON.stringify(fullDataToBackEnd),
      headers: {
        'Content-Type': 'application/json',
      },
      credentials:'include'
    })
      .then((res) => res.json())
      .then((data) => data.clientSecret)
      .catch((error)=>{
        toast.error(`Network Error: ${error.message}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
      });
      })
  }, []);
  toast.success('Your order has been registered!', {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
});
  const options = {fetchClientSecret};
  console.log('Returning EmeddedCheckoutProvider')

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider
        stripe={stripePromise}
        options={options}
      >
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  )
}

//RETURN PART---------------------------------------------------------------------------------------------------


export default CheckoutForm;