import { NavLink } from "react-router-dom";

export default function DashSidePanel() {
    const stylesLI = "flex gap-2 rounded-sm hover:bg-SCS hover:text-tc animationLiLink";
    const styleLINK = "flex items-center justify-start px-4 py-2 w-full";

    const menuItems = [
        { to: '/dashboard/myinstances', icon: '☁', label: 'My instances' },
        { to: '/dashboard/rentnew', icon: '➕', label: 'Rent New' },
        { to: '/dashboard/history', icon: '⏲', label: 'History' },
        { to: '/dashboard/faq', icon: '?', label: 'FAQ' },
        { to: '/dashboard/feedback', icon: '✎', label: 'Feedback' },
        { to: '/dashboard/profile', icon: '⚇', label: 'Profile' },
    ];

    return (
        <div className="flex flex-col bg-SC h-screen w-fit p-4 border-t-SCS border-t">
            <div>
                <ul className="DashSideUL flex flex-col gap-2">
                    {menuItems.map((item) => (
                        <li key={item.to}>
                            <NavLink
                                to={item.to}
                                end
                                className={({ isActive }) =>
                                    `${stylesLI} ${styleLINK} ${isActive ? 'bg-PM text-TC' : 'bg-SC text-TR'}`
                                }
                            >
                                <p className="w-4 text-center mr-4">{item.icon}</p>
                                <p className="truncate">{item.label}</p>
                            </NavLink>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
}
