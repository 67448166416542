import React, { createRef, useEffect, useMemo, useRef, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import MyInstancesServerInfoSpecInfo from "./MyInstancesServerInfoSpecInfo";
import { fetchMyInstances } from "helpers/allServicesReducer";
import { APIdeleteInstance, APInewBackupRules, APIredeployInstance } from "helpers/apiCalls";
import { toast } from "react-toastify";

type SelectedInstance = string;
type MyInstance = {
    uri: any;
    assignedtouser: number;
    state: string;
    customname: string;
    ip: string;
    url: string;
    isassigned: boolean;
    port: number;
    region: string;
    paid: boolean;
    changedate: string;
    createddate: string;
    latency: number;
    credentialsusername: string;
    credentialspassword: string;
    persistentdirectory: string;
    healthstatus: string;
    serviceid: number;
    hash: string;
    forwardedports: {
        port: string;
        externalPort: string;
        from: string;
    }[];
    hwUtilization: {
        cpu: number;
        ram: number;
        storage: number;
        network: number;
    },
    id: number;
    saas: string;
    name: string;
    vcpu: number;
    ram: number;
    storage: number;
    price: number;
    currency: string;
    active: boolean;
    numberofinstance: number;
}

export default function MyInstancesServerInfo({ selectedId }: { selectedId: SelectedInstance }) {
    const allServices = useSelector((state: any) => state.services.myInstances)
    const [selectedServiceInfo, setSelectedServiceInfo] = useState<MyInstance | null>(allServices[0] || null);
    const dispatch = useDispatch<any>()
    const [loading, setLoading] = useState<boolean>(false);

    const actionSelectRef = createRef<HTMLSelectElement>()
    const isLoading = useSelector((state: any) => state.services.myInstancesLoading);
    const [selectModal, setSelectModal] = useState<[boolean, string]>([false, ''])
    useEffect(() => {
        const foundService = allServices.find((service: MyInstance) => service.hash === selectedId);
        setSelectedServiceInfo(foundService || allServices[0] || null);
    }, [selectedId, allServices]);
    useEffect(() => {
        if (selectModal[0] === true) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }

        return () => {
            document.body.style.overflow = 'auto';
        };
    }, [selectModal]);
    const consoleCSS = 'px-4 py-4 border-s border-PM shadow flex flex-col h-fit items-start ';

    if (!selectedServiceInfo) {
        return <div>No service available or service not found.</div>;
    }
    const handleSelectAction = async (e: any) => {
        const action = e.target.value;
        if (!action) return

        switch (action) {
            case 'redeploy':
                console.log('redeploying...');
                setSelectModal([false, ''])
                setSelectModal([true, 'redeploy'])
                break
            case 'backup':
                console.log('backing up...');
                setSelectModal([false, ''])
                setSelectModal([true, 'backup'])
                break
            case 'delete':
                console.log('deleting...');
                setSelectModal([false, ''])
                setSelectModal([true, 'delete'])
                break
            default:
                break
        }
        e.target.value = '';
    }

    const redeployInstance = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (!e) return
        setSelectModal([false, ''])

        const formData = new FormData(e.currentTarget)
        const formObject = Object.fromEntries(formData)

        toast.info('Redeployment started..', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            className: 'bg-AC text-TS',
        });
        const remove = await APIredeployInstance(selectedServiceInfo.hash, formObject);
        if (remove !== false) {
            dispatch(fetchMyInstances())
            toast.success('Redeployment successful!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                className: 'bg-AC text-TS',
            });
        } else {
            toast.error('Error while redeploying', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                className: 'bg-AC text-TS',
            });
        }
    }
    const updateBackupRules = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (!e) return
        setSelectModal([false, ''])

        const formData = new FormData(e.currentTarget)
        const formObject = Object.fromEntries(formData)

        toast.info('Update started..', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            className: 'bg-AC text-TS',
        });
        const remove = await APInewBackupRules(selectedServiceInfo.hash, formObject);
        if (remove !== false) {
            dispatch(fetchMyInstances())
            toast.success('Update successful!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                className: 'bg-AC text-TS',
            });
        } else {
            toast.error('Error while Updating', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                className: 'bg-AC text-TS',
            });
        }
    }

    const deleteInstance = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (!e) return
        setSelectModal([false, ''])

        toast.info('Deletion process started..', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            className: 'bg-AC text-TS',
        });
        const remove = await APIdeleteInstance(selectedServiceInfo.hash);
        if (remove !== false) {
            dispatch(fetchMyInstances())
            toast.success('Instance deleted!', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                className: 'bg-AC text-TS',
            });
        } else {
            toast.error('Error while Deleting', {
                position: "top-right",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                className: 'bg-AC text-TS',
            });
        }

    }
    const getSelecModalOptions = (action: string) => {
        switch (action) {
            case 'redeploy':
                return (
                    <form onSubmit={(e) => redeployInstance(e)} className="bg-SC px-12 py-8 rounded-sm">
                        <div className='absolute top-0 right-0'>
                            <button className=' px-4 py-2' onClick={() => setSelectModal([false,''])}>
                                X
                            </button>
                        </div>
                        <div className="flex flex-col">
                            <h3 className="code">Redeploy</h3>
                            <p>This instance will be forcefully redeployed</p>
                        </div>
                        <div className="flex items-center gap-2 py-4 ml-2">
                            <input name='withBackup' className="custom-checkbox appearance-none bg-SC  checked:bg-ACS rounded-sm checked:text-SC" type="checkbox" checked
                                onClick={(e) => {
                                    const target = e.target as HTMLInputElement;
                                    target.checked = !target.checked;
                                }}
                            /><p>Redeploy with last backup?</p>
                        </div>
                        <div className="mt-4">
                            <button type='submit' className='flex px-4 py-3 w-full justify-center rounded-md text-md font-medium bg-PM text-TC hover:bg-ACS hover:text-SC animate-primary items-center' disabled={loading}>
                                {loading === true ? (<span className="loader mr-4"></span>) : ''}  {loading === false ? 'Redeploy' : 'Redeploying'}
                            </button>

                        </div>
                    </form>
                )
            case 'backup':
                return (
                    <form onSubmit={(e) => updateBackupRules(e)} className="bg-SC px-12 py-8 rounded-sm">
                        <div className='absolute top-0 right-0'>
                            <button className=' px-4 py-2' onClick={() => setSelectModal([false,''])}>
                                X
                            </button>
                        </div>
                        <div className="flex flex-col">
                            <h3 className="code">Backup</h3>
                            <p>Period of automatic instance backup</p>
                        </div>
                        <div className="flex items-center gap-2 py-8 ml-2">
                            <select name="backupPeriod" id="" className="text-TR appearance-none w-fit pr-4 pl-3 h-full rounded-sm bg-SCS focus:outline-none focus:ring-1 focus:ring-ACS">
                                <option value="7d">7 Days</option>
                                <option value="1d">1 day</option>
                                <option value="12h">12 hours</option>
                                <option value="1h">1 hour</option>
                                <option value="30min">30 minutes</option>
                                <option value="5min"> 5 minutes</option>
                            </select>
                        </div>
                        <div className="mt-4">
                            <button type='submit' className='flex px-4 py-3 w-full justify-center rounded-md text-md font-medium bg-PM text-TC hover:bg-ACS hover:text-SC animate-primary items-center' disabled={loading}>
                                {loading === true ? (<span className="loader mr-4"></span>) : ''}  {loading === false ? 'Save' : 'Saving...'}
                            </button>
                        </div>
                    </form>
                )
            case 'delete':
                return (
                    <form onSubmit={(e) => deleteInstance(e)} className="bg-SC px-12 py-8 rounded-sm">
                        <div className='absolute top-0 right-0'>
                            <button className=' px-4 py-2' onClick={() => setSelectModal([false,''])}>
                                X
                            </button>
                        </div>
                        <div>
                            <h3 className="code">Are you sure?</h3>
                            <p>This will permanently delete and unsubscribe your instance</p>
                        </div>
                        <div className="py-4  ml-2">
                            <p>{selectedServiceInfo.customname}</p>
                            <p>{selectedServiceInfo.saas}</p>
                            <p>{selectedServiceInfo.createddate}</p>
                            <p>{selectedServiceInfo.uri}</p>
                        </div>
                        <div className="mt-4">
                            <button type='submit' className='flex px-4 py-3 w-full justify-center rounded-md text-md font-medium bg-transparent text-TC border-BG border-2 hover:bg-BD hover:border-BD  animate-primary items-center' disabled={loading}>
                                {loading === true ? (<span className="loader mr-4"></span>) : ''}  {loading === false ? 'Delete' : 'Deleting..'}
                            </button>

                        </div>
                    </form>
                )
            default:
                setSelectModal([false, ''])
        }
    }

    return (
        <div className='flex flex-col w-full h-full'>
            {/*Cesta*/}
            <div className='flex w-full h-fit py-2 pl-2 pr-10 border-b border-SCS justify-between '>
                <p className='hidden md:block ml-2'> \ Virtual Deployment \ Instance \ {selectedServiceInfo.customname}</p>

            </div>

            {/*Actions*/}

            <div className='flex w-full h-fit items-center gap-2 justify-between pl-2 pr-8 py-1 border-b border-SC bg-SCS'>
                <div>
                    <p className='hidden md:block code px-2'>Forward Instance Control Console</p>
                </div>
                <div className='flex items-center gap-4 h-8'>
                    <button onClick={() => dispatch(fetchMyInstances())} className={`flex items-center gap-2 bg-SC px-3 h-full focus:ring-1 focus:ring-ACS rounded-sm ${isLoading === true ? '' : 'focus:ring-transparent'} `}>
                        <p className={`${isLoading === true ? 'loadingAnimation' : ''}`}>↻</p>
                    </button>

                    <select ref={actionSelectRef} onChange={(e) => handleSelectAction(e)} name="actionsSelect" id="actionSelect" className="appearance-none w-fit pr-4 pl-3 h-full rounded-sm bg-SC focus:outline-none focus:ring-1 focus:ring-ACS ">
                        <option value="" hidden>{'⏷   Actions'}</option>
                        <option value="redeploy">Redeploy</option>
                        <option value="backup">Backup</option>
                        <option value="delete" className='text-red-500 '>Delete</option>
                    </select>
                    <button className='flex items-center gap-2 bg-SC px-4 h-full focus:ring-1 focus:ring-ACS rounded-sm'>
                        Report
                    </button>
                </div>
            </div>
            {/*ConsolePanel___*/}

            <div className='w-full h-fit bg-SCS flex md:flex-row flex-col'>
                <div className='px-4 py-12 grid grid-cols-1 md:grid-cols-2 grid-rows-4 md:w-2/3'>
                    {/* Dummy Data Divs */}
                    <div className={consoleCSS}>
                        <h3 className='text-lg font-semibold'>Instance ID</h3>
                        <p>{selectedServiceInfo.hash || "-"}</p>
                    </div>
                    <div className={consoleCSS}>
                        <h3 className='text-lg font-semibold'>Public IPv4 Address</h3>
                        <div className='flex items-center gap-4'>
                            <p>{selectedServiceInfo.ip || "-"}</p>
                            <button className='text-SC hover:text-ACS'>⧉</button>
                        </div>
                    </div>
                    <div className={consoleCSS}>
                        <h3 className='text-lg font-semibold'>Instance State</h3>
                        <p>{selectedServiceInfo.state || "-"}</p>
                    </div>
                    <div className={consoleCSS}>
                        <h3 className='text-lg font-semibold'>Instance Type</h3>
                        <p>{selectedServiceInfo.name || "-"}</p>
                    </div>
                    <div className={consoleCSS}>
                        <h3 className='text-lg font-semibold'>URL</h3>
                        <div className='flex items-center'>
                            <p className="w-32 overflow-hidden">{selectedServiceInfo.url || "-"}</p>
                            <button className='text-SC hover:text-ACS pl-4'>
                                <a href={`//${selectedServiceInfo.url}`} target="_blank" rel="noopener noreferrer">⧉</a>
                            </button>                        </div>
                    </div>
                    <div className={consoleCSS}>
                        <h3 className='text-lg font-semibold'>Launch Time</h3>
                        <p>{selectedServiceInfo.createddate || "-"}</p>
                    </div>
                    <div className={consoleCSS}>
                        <h3 className='text-lg font-semibold'>Forwarded Ports</h3>
                        {selectedServiceInfo.forwardedports && selectedServiceInfo.forwardedports[0] ? (
                            selectedServiceInfo.forwardedports.map((port, index) => (
                                <p key={index}>from {port.port} to {port.externalPort}</p>
                            ))
                        ) : (
                            <p>No forwarded ports</p>
                        )}
                    </div>

                    <div className={consoleCSS}>
                        <h3 className='text-lg font-semibold'>Username</h3>
                        <p>{selectedServiceInfo.credentialsusername || "-"}</p>
                    </div>
                    <div className={consoleCSS}>
                        <h3 className='text-lg font-semibold'>Password</h3>
                        <p>{selectedServiceInfo.credentialspassword || "-"}</p>
                    </div>
                    <div className={consoleCSS}>
                        <h3 className='text-lg font-semibold'>Persistent directory</h3>
                        <div className='flex items-center gap-4'>
                            <p>{selectedServiceInfo.persistentdirectory || "-"}</p>
                            <button className='text-SC hover:text-ACS'>⧉</button>
                        </div>

                    </div>
                </div>
                <div className='w-full md:w-fit flex flex-col mx-auto '>
                    <MyInstancesServerInfoSpecInfo selectedServiceInfo={selectedServiceInfo} />
                </div>
            </div>

            {selectModal[0] &&
                <div onClick={() => setSelectModal([false, ''])} className={`${selectModal[0] === true ? 'flex' : 'hidden'} loginModal items-center justify-center fixed left-0 top-0 w-full h-screen  `}>

                    <div onClick={(e) => e.stopPropagation()} className='w-fit h-fit border border-ACS z-50 relative shadow-md shadow-ACS'>
                        {
                            getSelecModalOptions(selectModal[1])
                        }

                    </div>
                </div>}



            {/*___ConsolePanel*/}

        </div>
    );
}