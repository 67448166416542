import MongoDB from '../assets/services/mongodb.png';
import Grafana from '../assets/services/grafana.png';
import NodeRED from '../assets/services/nodered.png';
import PostGre from '../assets/services/postgre.png';
import WooCommerce from '../assets/services/woocommerce.png';
import WordPress from '../assets/services/wordpress.png';
import Ubuntu from 'assets/services/ubuntu.png';
import { useCallback, useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import MyInstancesServerList from 'components/MyInstancesServerList';
import MyInstancesServerInfo from 'components/MyInstancesServerInfo';
import serverPic from 'assets/serverPic.png';
import { fetchMyInstances } from 'helpers/allServicesReducer';

export default function MyInstances() {
    const serviceSelector = useSelector((state: any) => state.services.myInstances);
    const [selectedIdOfService, setselectedIdOfService] = useState(
        (serviceSelector[0] ? serviceSelector[0].hash : null));
    const [doMyInstancesCall, setDoMyInstancesCall] = useState<boolean>(false)
    const dispatch = useDispatch<any>()

    const showSelectedService = (id: any) => {
        setselectedIdOfService(id);
    };

    useEffect(() => {
        const result = serviceSelector.some((inst: any) => inst.state !== 'running')
        if (result) setDoMyInstancesCall(prev => !prev)
    }, [serviceSelector])

    useEffect(() => {
        if (doMyInstancesCall) {
            dispatch(fetchMyInstances());
            setDoMyInstancesCall(false);
        }
    }, [doMyInstancesCall])

    const zeroServices = () => {
        return (
            <div className="flex h-screen w-full border bg-SC border-SCS flex-col gap-4">
                <h3 className='code w-full text-center pt-16 '>You currently dont have any server rented, feel free to be proud owner</h3>
                <p className='text-center'>Rent new instance today</p>
                <div className='flex justify-center py-12'>
                    <img src={serverPic} alt='Server' className='w-64' />

                </div>
            </div>
        )
    }
    return (
        serviceSelector && serviceSelector.length > 0 ? (
            <div className="flex md:flex-row flex-col h-fit md:h-screen w-full border border-SCS ">
                <div className="flex md:flex-col flex-row md:w-fit w-full bg-SC py-4 pt-10 px-4 border-r border-SCS overflow-x-scroll gap-4">
                    <MyInstancesServerList
                        myInstances={serviceSelector}
                        showSelectedService={showSelectedService}
                        selectedId={selectedIdOfService}
                    />
                </div>
                <div className="flex bg-SC w-full ">
                    <MyInstancesServerInfo selectedId={selectedIdOfService} />
                </div>
            </div>
        ) : zeroServices()
    );
}
