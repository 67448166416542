export const themes = [
  "aurora",
  "cobalt2",
  "dark",
  "dracula",
  "espresso-libre",
  "github-dark",
  "github-dark-default",
  "github-dark-dimmed",
  "midnight-red",
  "monoindustrial",
  "monokai",
  "monokai-dimmed",
  "night-owl",
  "noctis",
  "noctis-azureus",
  "noctis-bordo",
  "noctis-minimus",
  "noctis-obscuro",
  "noctis-sereno",
  "noctis-uva",
  "noctis-viola",
  "oceanic-next",
  "oled",
  "one-dark-pro",
  "one-dark-pro-darker",
  "railscasts-extended",
  "selenized-dark",
  "selenized-light",
  "solarized-dark",
  "solarized-light",
  "tokyo-night",
  "tokyo-night-light",
  "tokyo-night-storm",
  "totallyinformation",
  "zenburn"
];

export const GIT_PIC_PATH = 'https://raw.githubusercontent.com/node-red-contrib-themes/theme-collection/refs/heads/screenshots/screenshots/'
export const GIT_PIC_PATH_NOAB = 'https://iclabs.eu/serverPic.png'

