import { useEffect } from 'react';
import logo from '../assets/logo1.png'; 
import { useLocation } from 'react-router-dom'
export default function Footer() {
    const {pathname } = useLocation()

    useEffect(()=>{
        window.scrollTo(0,0)
    },[pathname])
    return (
        <footer className='bg-SC text-TC'>
            <div className='flex p-8'>
                <div className='w-full items-center md:items-start md:w-fit flex flex-col gap-8'>
                    <div className='flex gap-4 items-center'>
                        <img src={logo} className='w-8' alt="" />
                        <h4 className='font-bold'>ICLabs</h4>
                    </div>
                    <p>&copy; 2024 ICL. All rights reserved.</p>

                </div>

                <div className='hidden md:flex flex-wrap justify-between w-full px-16 py-4 text-TC'>
                    <ul>
                        <li className='text-ACS mb-2 font-bold'>Services</li>
                        <li>Instances Rental</li>
                        <li>Instance Backup</li>
                        <li>Instance Sense</li>
                        <li>Infra Solutions</li>
                    </ul>
                    <ul>
                        <li className='text-ACS mb-2 font-bold'>Company</li>
                        <li>About Us</li>
                        <li>Our Team</li>
                        <li>Partners</li>
                    </ul>
                    <ul>
                        <li className='text-ACS mb-2 font-bold'>Resources</li>
                        <li>Documentation</li>
                        <li>Blog</li>
                        <li>FAQ</li>
                    </ul>
                    <ul>
                        <li className='text-ACS mb-2 font-bold'>Contact</li>
                        <li>Support</li>
                        <li>Solutions</li>
                    </ul>
                    <ul>
                        <li className='text-ACS mb-2 font-bold'>Legal</li>
                        <li>Terms of Service</li>
                        <li>Privacy Policy</li>
                        <li>Cookie Policy</li>
                    </ul>
                    <ul>
                        <li className='text-ACS mb-2 font-bold'>Follow Us</li>

                        <li><svg className='bg-TR rounded-lg' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg></li>
                    </ul>

                </div>
            </div>

            
            

        </footer>
    );
}