export async function APIdeleteInstance(hash: string): Promise<boolean> {
    return fetch('https://iclabs.eu/backend/api/deployment/takedown', {
        method: 'POST',
        credentials:'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ hash })
    })
        .then((response) => {
            if (response.ok) {
                return true;
            } else {
                throw new Error('Failed to delete instance');
            }
        })
        .catch((error) => {
            console.error('Error:', error)
            return false
        })
}
export async function APIredeployInstance(hash: string,form:{[k:string]:FormDataEntryValue}): Promise<boolean> {
    return fetch('https://iclabs.eu/backend/api/deployment/redeploy', {
        method: 'POST',
        credentials:'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ hash,form })
    })
        .then((response) => {
            if (response.ok) {
                return true;
            } else {
                throw new Error('Failed to redeploy instance');
            }
        })
        .catch((error) => {
            console.error('Error:', error)
            return false
        })
}
export async function APInewBackupRules(hash: string,form:any): Promise<boolean> {
    return fetch('https://iclabs.eu/backend/api/deployment/backup', {
        method: 'POST',
        credentials:'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ hash })
    })
        .then((response) => {
            if (response.ok) {
                return true;
            } else {
                throw new Error('Failed to update backup');
            }
        })
        .catch((error) => {
            console.error('Error:', error)
            return false
        })
}

export async function APIchangeInstanceConfig(hash: string,form:any): Promise<boolean> {
    return fetch('https://iclabs.eu/backend/api/deployment/change', {
        method: 'POST',
        credentials:'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ hash, form })
    })
        .then((response) => {
            if (response.ok) {
                return true;
            } else {
                throw new Error('Failed to update backup');
            }
        })
        .catch((error) => {
            console.error('Error:', error)
            return false
        })
}

export async function APInotifyICL(message: string | string[],critical:boolean=false): Promise<boolean> {
    return fetch('https://iclabs.eu/backend/api/notify', {
        method: 'POST',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify({ message,critical })
    })
        .then((response) => {
            if (response.ok) {
                return true;
            } else {
                throw new Error('Failed to notify ICL');
            }
        })
        .catch((error) => {
            console.error('Error:', error)
            return false
        })
}

export async function APIgetAssets(
    command: 'all' | 'specific' | 'included',
    path: string,
    data?: string
): Promise<Buffer[] | Buffer | boolean> {
    const url = new URL('https://iclabs.eu/backend/api/assets')
    url.searchParams.append('command', command)
    url.searchParams.append('path', path)
    if (data) {
        url.searchParams.append('data', data)
    }

    return fetch(url.toString(), {
        method: 'GET',
        credentials: 'include',
        headers: {
            'Content-Type': 'application/json',
        }
    })
        .then((response) => {
            if (response.ok) {
                return response.arrayBuffer().then(buffer => Buffer.from(buffer));
            } else {
                throw new Error('Failed to update backup');
            }
        })
        .catch((error) => {
            console.error('Error:', error);
            return false;
        });
}